import useTranslation from 'next-translate/useTranslation'
import styles from './style.module.css'

export default function WorkLink ({
  href = 'https://anubi.io'
}) {
  const { t } = useTranslation('work')
  return (
    <div className={styles.container}>
      <a
        href={href}
        target='_blank'
        rel='noreferrer'
        alt={t('visitwebsite')}
      >
        <div className={styles.link}>
          {t('visitwebsite')}
        </div>
      </a>
    </div>
  )
}
