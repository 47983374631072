import styles from './style.module.css'

export default function WorkCaption ({
  text = null
}) {
  if (!text || text === '') return null
  return (
    <div className={styles.caption}>
      {text}
    </div>
  )
}
