import Image from 'next/image'
import WorkCaption from '../WorkCaption'
import styles from './style.module.css'

export default function WorkImageRow ({
  images = [],
  caption = null,
  quality = 100
}) {
  return (
    <>
      <div
        className={styles.grid}
        style={{
          gridTemplateColumns: `repeat(${images.length}, 1fr)`
        }}
      >
        {images.map((src, i) => (
          <Image
            key={i}
            className={styles.image}
            style={{
              gridArea: `1 / ${i + 1} / 2 / ${i + 2}`
            }}
            alt={`${i}`}
            src={src}
            placeholder='blur'
            quality={quality || 100}
          />
        ))}
      </div>
      <WorkCaption text={caption} />
    </>
  )
}
